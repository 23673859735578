
import { defineComponent, ref, reactive, inject, computed, toRef } from 'vue'
import {
  device,
  employee,
  modalInstance,
  notifyInstance,
  orderRecord
} from '@/type'
import axios from '@/http'
import { useStore } from 'vuex'
import DateRangePicker from '@/views/components/dateRangePicker.vue'
import moment from 'moment'
import usePagination from '@/plugins/usePagination'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

interface pagination {
  getData: () => void
  go: (page: number) => void
  next: () => void
  pre: () => void
}

export default defineComponent({
  components: {
    DateRangePicker
  },
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const globalVariable = computed(() => store.getters.globalVariable)
    const paymentsMap = computed(() => globalVariable.value.payments)
    const cardTypesMap = computed(() => globalVariable.value.cardTypes)
    const discountsMap = computed(() => globalVariable.value.discounts)
    const fromMap = computed(() => globalVariable.value.from)

    const exportType = ref('Succeed')

    const employees = ref(new Array<employee>())
    const devices = ref(new Array<device>())
    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 10,
      lastPage: 1,
      total: 0,
      orderRecords: ref(new Array<orderRecord>())
    })

    const statistics = reactive({
      onlineOrder: 0,
      onlineOrderQuantity: 0,
      storeOrder: 0,
      storeOrderQuantity: 0,
      discounts: new Array<any>()
    })

    const range = reactive({
      start: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
      end: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
    })

    const paginationSetting = reactive({
      page: toRef(paginationData, 'page'),
      pageSize: toRef(paginationData, 'pageSize'),
      api: `/orders/succeeded/${storeId.value}`,
      payload: {
        startTime: toRef(range, 'start'),
        endTime: toRef(range, 'end')
      }
    })

    function fetchStatistics() {
      axios
        .get(`/orders/succeeded/${storeId.value}/statistics`, {
          params: {
            startTime: range.start,
            endTime: range.end
          }
        })
        .then((res) => {
          const data = res.data
          statistics.onlineOrder = Number(data.onlineOrder)
          statistics.onlineOrderQuantity = Number(data.onlineOrderQuantity)
          statistics.storeOrder = Number(data.storeOrder)
          statistics.storeOrderQuantity = Number(data.storeOrderQuantity)
          statistics.discounts = data.discounts
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function init() {
      const initEmployee = axios
        .get(`/employees/${storeId.value}`)
        .then((res) => {
          const data = res.data
          employees.value = data.employees
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
      const initDevice = axios
        .get(`/devices/${storeId.value}`)
        .then((res) => {
          const data = res.data
          devices.value = data.devices
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
      const initPagination = usePagination(paginationSetting).then((res) => {
        pagination = res
      })

      Promise.all([initEmployee, initDevice, initPagination]).then(function () {
        fetchStatistics()
        getData()
      })
    }

    function getEmployeeName(employeeNo: string) {
      const employee = employees.value.find((employee: employee) => {
        return employee.no === employeeNo
      })
      if (employee !== undefined) {
        return employee.name
      }
      return ''
    }

    function getDeviceName(deviceId: number) {
      const device = devices.value.find((device: device) => {
        return parseInt(device.id) === deviceId
      })
      if (device !== undefined) {
        return device.name
      }
      return ''
    }

    function updateRange(updateRange: any) {
      range.start = moment(updateRange.start).format('YYYY-MM-DDTHH:mm')
      range.end = moment(updateRange.end).format('YYYY-MM-DDTHH:mm')
      fetchStatistics()
      goPage(1)
    }

    init()

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.orderRecords = data.items
    }

    function formatNumber(number: any) {
      return number === undefined ? 0 : parseInt(number).toLocaleString('en-US')
    }

    function formatDateTime(time: any) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            startTime: range.start,
            endTime: range.end
          },
          modal,
          notify,
          router
        )
      }
    }

    function orderPosTypeRawHtml(orderPosType: any) {
      switch (orderPosType) {
        case 'store':
          return '<span class="u-mrXs">門市</span>'
        case 'storePre':
          return '<span class="u-mrXs">門市</span><span class="c-squareTag c-squareTag--og">預</span>'
        case 'storePartialPickup':
          return '<span class="u-mrXs">門市</span><span class="c-squareTag c-squareTag--og">寄</span>'
        case 'webPayment':
          return '<span class="u-mrXs">線上</span><span class="c-squareTag c-squareTag--bu">付</span>'
        case 'webPrePayment':
          return '<span class="u-mrXs">線上</span><span class="c-squareTag c-squareTag--og">預</span><span class="c-squareTag c-squareTag--bu">付</span>'
        default:
          return ''
      }
    }

    return {
      formatNumber,
      formatDateTime,
      range,
      updateRange,
      paymentsMap,
      cardTypesMap,
      discountsMap,
      fromMap,
      paginationData,
      prePage,
      nextPage,
      goPage,
      getEmployeeName,
      getDeviceName,
      statistics,
      exportExcelClick,
      orderPosTypeRawHtml
    }
  }
})
